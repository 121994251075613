import { includeScript, includeScripts } from './includeHelper';

import cyberSource from './cyber_source/index';
import genericThreeDSecure from './generic_three_d_secure/index';
import braintreeHelpers from './braintree_blue';
import adyenHelpers from './adyen';

const init = (config, callbacks) => {
    switch (config.gateway) {
        case 'cybersource':
            initCyberSource(config, callbacks);
            break;
        case 'adyen':
            adyenHelpers.init(config);
            break;
        case 'braintree_blue':
            initBraintree(config, callbacks);
            break;
        case 'quickpay':
            break; // no additional init
        case 'payment_express':
            break; // no additional init
        default:
            console.warn('3D Secure initialization failed. Unsupported gateway');
    }
};

const initCardAuthorization = (formData, config, callbacks) => {
    switch (config.gateway) {
        case 'cybersource':
            cyberSource.authorize(formData, config, callbacks);
            break;
        case 'quickpay':
        case 'payment_express':
            genericThreeDSecure.authorize(formData, config, callbacks);
            break;
        case 'braintree_blue':
            braintreeHelpers.verifyCard(formData, config, callbacks);
            break;
        case 'adyen':
            adyenHelpers.startAuthorization(callbacks);
            break;
        default:
            console.warn('3D Secure card authorization failed. Unsupported gateway');
    }
};

const challengeRequested = (gateway, threeDSData) => {
    switch (gateway) {
        case 'cybersource':
            return threeDSData.enrollment != null;
        case 'adyen':
            return threeDSData != null;
        default:
            return false;
    }
};

const initChallenge = (gateway, threeDSData, onThreeDSSuccess) => {
    switch (gateway) {
        case 'cybersource':
            cyberSource.startChallenge(threeDSData, onThreeDSSuccess);
            break;
        case 'adyen':
            adyenHelpers.showChallengeModal(threeDSData, onThreeDSSuccess);
            break;
        default:
            break;
    }
};

const initCyberSource = (config, callbacks) => {
    includeScript(config.songbird_src).then(() => (
        cyberSource.init(config, callbacks)
    ));
};

const initBraintree = (config, callbacks) => {
    includeScripts(config.braintree_srcs).then(() => (
        braintreeHelpers.init(config, callbacks)
    ));
};

export default {
    init,
    initCardAuthorization,
    challengeRequested,
    initChallenge,
};
