
var getFieldLabel = function(field, options) {
    var label = field.label;
    var additionalLabel = '';

    if (field.required && options.requiredLabel) {
        additionalLabel = ' <span class="cfy-additional-label">' + (options.requiredLabel || '') + '</span>';
    } else if (!field.required) {
        additionalLabel = ' <span class="cfy-additional-label">' + (options.optionalLabel || '(optional)') + '</span>';
    }

    return label + additionalLabel;
};

var getSelector = function(elementType, fieldName) {
    var prefix = '.cfy-' + elementType;

    if (fieldName) {
        return prefix + '--' + fieldName;
    }

    return prefix;
};

var show = function(elem, displayAs) {
    displayAs = displayAs == null ? 'block' : displayAs;
    elem = document.querySelector(elem);
    if (elem == null) return;
    elem.style.display = displayAs;
};

var hide = function(elem) {
    if (typeof elem === 'string') {
        elem = document.querySelector(elem);
    }

    if (elem == null) return;
    elem.style.display = 'none';
};

export default {
    getFieldLabel: getFieldLabel,
    getSelector: getSelector,
    show: show,
    hide: hide
};
