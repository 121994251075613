import config from './config/config';
import iframeHelper from './helpers/iframeHelper';
import optionsHelper from './helpers/optionsHelper';
import optionsValidationHelper from './helpers/optionsValidationHelper';
import callbacksValidationHelper from './helpers/callbacksValidationHelper';
import loggerHelper from './helpers/loggerHelper';
import arrayObjectHelper from './helpers/arrayObjectHelper';
import defaults from './config/fieldDefaults';
import threeDSecureHelper from './helpers/threeDSecureHelper';
import genericThreeDSecureHelper from './helpers/generic_three_d_secure/index';
import applePayIframe from './helpers/apple_pay/iframe';
import payPalHelper from './helpers/pay_pal/index';
import goCardlessHelper from './helpers/go_cardless';
import ChargifyBadger from './helpers/chargifyBadger.js';
import hosts from './validators/hosts';

function Chargify() {
    var loadUserOptions = {};
    var globalUserOptions = {};
    let globalCallbacks = {};
    var iframeElems = {};
    var tokenFunctions = {
        success: null,
        error: null,
        token: null
    };

    var primaryIframeSelector = '';
    var jsHost;
    var threeDSFormData = { amount: 0 };
    var threeDSConfig = {};
    var threeDSSuccess = false;
    var validForm = false;
    const threeDSecureIframeId = '999';

    var unload = function() {
        if (Object.keys(globalUserOptions).length !== 0) {
            window.removeEventListener('message', receiveMessage, false);

            var iframeSelectors = iframeHelper.getIframeSelectors(globalUserOptions);

            if (iframeSelectors !== null) {
                iframeSelectors.forEach(function(selector) {
                    if (document.querySelector(selector) == null) {
                        return;
                    }

                    document.querySelector(selector).childNodes.forEach(function(iframe) {
                        document.querySelector(selector).removeChild(iframe);
                    });
                });

                iframeElems = {};
            }
        }

        if (globalUserOptions.type === 'gocardless') {
            goCardlessHelper.unload();
        }

        if (globalUserOptions.threeDSecure === true) {
            destroyThreeDSecureIframe();
        }
    };

    var load = function(userOptions, callbacks = {}) {
        try {
            unload();

            loadUserOptions = userOptions;
            userOptions.selector = userOptions.selector || globalUserOptions.selector;
            userOptions.publicKey = userOptions.publicKey || globalUserOptions.publicKey;
            userOptions.serverHost = userOptions.serverHost || globalUserOptions.serverHost;

            const optionsValidation = optionsValidationHelper.validateOptions(userOptions);
            const callbacksValidation = callbacksValidationHelper.validateCallbacks(callbacks);
            if (!optionsValidation.result || !callbacksValidation.result) {
                const errors = [...optionsValidation.errors, ...callbacksValidation.errors];
                return console.warn('Chargify options are invalid', errors); // eslint-disable-line no-console
            }

            jsHost = fetchJsHost();
            if (!jsHost) {
                console.error('Chargfify.js src URL is invalid'); // eslint-disable-line no-console
                return;
            }

            globalCallbacks = callbacks;
            window.addEventListener('message', receiveMessage, false);
            globalUserOptions = optionsHelper.getNormalizedOptions(userOptions);
            primaryIframeSelector = optionsHelper.getPrimaryIframeSelector(globalUserOptions);

            const context = getContext();
            applePayIframe.init(context);
            payPalHelper.init(context);

            if (userOptions.type === 'gocardless') {
                const goCardlessConfig = {
                    globalUserOptions,
                    globalCallbacks,
                    iframeElems,
                    primaryIframeSelector,
                    loadUserOptions,
                    iframeSrc: jsHost + config.server.iframePath,
                    onIframeLoadCallback: sendMessage,
                    createIframes,
                    sendMessageToAllIframes,
                    token,
                };

                goCardlessHelper.init(goCardlessConfig);
                goCardlessHelper.createLoadGoCardlessDataIframe();
            } else if (userOptions.type === 'apple_pay') {
                applePayIframe.createLoadApplePayDataIframe();
            } else if (userOptions.type === 'pay_pal') {
                payPalHelper.createLoadPayPalDataIframe();
            } else if (userOptions.threeDSecure) {
                Object.keys(globalUserOptions.fields).forEach(function(fieldName) {
                    threeDSFormData[fieldName] = '';
                });

                createThreeDSecureIframe();
            } else {
                createIframes();
            }
        } catch (error) {
            ChargifyBadger.notify(error);
            throw (error);
        }
    };

    // Depends on correctly initialised global variables
    const getContext = () => ({
        primaryIframeSelector,
        globalUserOptions,
        iframeElems,
        sendMessage,
        sendMessageToAllIframes,
        createIframes,
        globalCallbacks,
        iframeSrc: jsHost + config.server.iframePath,
    });

    const createThreeDSecureIframe = function() {
        const src = jsHost + config.server.iframePath;
        const { serverHost, publicKey, gatewayHandle } = globalUserOptions;

        iframeHelper.createDataIframe(primaryIframeSelector, src, 'three-ds-load-data')
            .then((iframe) => {
                iframeElems[threeDSecureIframeId] = iframe;

                sendMessage({
                    iframeId: threeDSecureIframeId,
                    action: 'FETCH_THREE_D_SECURE_CONFIG',
                    data: {
                        serverHost: serverHost,
                        publicKey: publicKey,
                        amount: threeDSFormData.amount,
                        gatewayHandle: gatewayHandle,
                    }
                });
            });
    };

    const destroyThreeDSecureIframe = function() {
        iframeHelper.destroyDataIframe(
            primaryIframeSelector,
            'three-ds-load-data',
            iframeElems,
            threeDSecureIframeId
        );
    };

    const onThreeDSSuccess = (token, options = { skipCallingTokenFunction: false }) => {
        setThreeDsToken(token);
        if (!options.skipCallingTokenFunction) {
            tokenFunctions.token();
        }
    };

    const receivedThreeDSConfig = function(data) {
        threeDSConfig = {
            ...data,
            threeDSVerificationAmount: loadUserOptions.threeDSVerificationAmount,
        };

        primaryIframeSelector = optionsHelper.getPrimaryIframeSelector(globalUserOptions);

        destroyThreeDSecureIframe();

        if (Object.getOwnPropertyNames(iframeElems).length === 0) {
            createIframes();
        }

        threeDSecureHelper.init(threeDSConfig, {
            onSuccess: onThreeDSSuccess,
            onError: display3DSError,
        });
    };

    const receivedThreeDSConfigError = function(data) {
        if (globalCallbacks.onThreeDsConfigError) {
            globalCallbacks.onThreeDsConfigError(data);
        }

        globalUserOptions.threeDSecure = false;
        destroyThreeDSecureIframe();
        createIframes();
    };

    var setThreeDsToken = function(token) {
        threeDSSuccess = true;
        sendMessageToAllIframes({ action: 'SET_THREE_DS_TOKEN', data: token });
    };

    var display3DSError = function(errorDescription, message = '3D Secure authentication failed.') {
        threeDSSuccess = false;
        if (tokenFunctions.error) {
            tokenFunctions.error({ status: 400, errors: [message, errorDescription] });
        } else {
            return console.warn(message, errorDescription); // eslint-disable-line no-console
        }
    };

    var initCardAuthorization = function() {
        threeDSecureHelper.initCardAuthorization(threeDSFormData, threeDSConfig, {
            onSuccess: onThreeDSSuccess,
            onError: (error, message) => {
                const msg = message || '3D Secure authorization could not be started.';
                validForm = false;
                display3DSError(error, msg);
            },
            initGenericThreeDSecureModal: (formData, storeCardInitUrl) => {
                createGenericThreeDSecureModalIframe(formData, storeCardInitUrl);
            }
        });
    };

    var receiveValidationSuccess = function() {
        validForm = true;
        tokenFunctions.token();
    };

    var receiveValidationError = function() {
        validForm = false;
    };

    const fetchJsHost = function() {
        const scripts = document.scripts;

        for (let i = 0; i < scripts.length; i++) {
            const src = scripts[i].getAttribute('src');
            if (src == null) continue;

            const found = hosts.matchJsHost(src);
            if (found) return found[1].replace(/\/$/, '');
        }
    };

    const createGenericThreeDSecureModalIframe = function(formData, storeCardInitUrl) {
        const src = jsHost + config.server.iframePath;
        const styles = genericThreeDSecureHelper.iframeStyles();

        iframeHelper.createDataIframe(primaryIframeSelector, src, 'three-ds-load-data', styles)
            .then((iframe) => {
                iframeElems[threeDSecureIframeId] = iframe;

                const message = {
                  action: 'CREATE_GENERIC_THREE_D_SECURE_IFRAME',
                  data: {
                      formData: formData,
                      storeCardInitUrl: storeCardInitUrl,
                  },
                };
                iframe.contentWindow.postMessage(message, jsHost); // Why not use sendMessage??
            });
    };

    var createIframes = function() {
        var iframeSelectors = iframeHelper.getIframeSelectors(globalUserOptions);

        iframeSelectors.forEach(function(selector, index) {
            if (document.querySelector(selector) == null) {
                return;
            }

            var iframe = document.createElement('iframe');
            iframe.name = iframeHelper.getIframeName(globalUserOptions.type, selector);
            iframe.style.border = 'none';
            iframe.style.height = '84px'; // initial value, overwritten dynamically
            var styles = globalUserOptions.style && globalUserOptions.style[selector];
            if (styles) {
                Object.keys(globalUserOptions.style[selector]).forEach(function(eachStyle) {
                    iframe.style[eachStyle] = styles[eachStyle];
                });
            }

            document.querySelector(selector).appendChild(iframe);
            iframe.contentWindow.document.open('id' + selector);

            var src = jsHost + config.server.iframePath;
            iframe.contentWindow.location.replace(src);

            iframe.onload = function() {
                sendMessage({
                    iframeId: index,
                    action: 'LOAD',
                    data: arrayObjectHelper.assign({}, globalUserOptions, {
                        selfSelector: selector,
                        selfIndex: index,
                        primaryIframeSelector: primaryIframeSelector
                    })
                });
            };
            iframe.contentWindow.document.close();
            iframeElems[index] = iframe;
        });
    };

    var resizeIframe = function(data) {
        try {
            var iframe = document.querySelector(data.selector + ' iframe');

            if (!iframe) return;

            iframe.style.width = data.width + 'px';
            iframe.style.height = data.height + 'px';
        } catch (error) {
            ChargifyBadger.notify(error);
            throw (error);
        }
    };

    const token = (externalFormData) => (formEl, success, error) => {
        try {
            if (formEl == null) {
                return console.warn('first argument (form element) can not be blank'); // eslint-disable-line no-console
            }

            if (formEl != null && formEl.querySelectorAll == null) {
                return console.warn('first argument (form element) must be a valid form element'); // eslint-disable-line no-console
            }

            if (success == null) {
                return console.warn('second argument (successCallback) can not be blank'); // eslint-disable-line no-console
            }

            if (success != null && typeof success !== 'function') {
                return console.warn('second argument (successCallback) should be a function'); // eslint-disable-line no-console
            }

            if (error != null && typeof error !== 'function') {
                return console.warn('third argument (failureCallback) should be a function'); // eslint-disable-line no-console
            }

            tokenFunctions.success = success;
            tokenFunctions.error = error;
            if (!tokenFunctions.token) {
                tokenFunctions.token = function() {
                    token(externalFormData)(formEl, success, error);
                };
            }

            if (globalUserOptions.type === 'gocardless' && goCardlessHelper.goCardlessDataNotConfirmed()) {
                if (goCardlessHelper.noGoCardlessInformations()) return;

                goCardlessHelper.openGoCardlessConfirmationModal(formEl, success, error, externalFormData);

                return;
            } else if (loadUserOptions.threeDSecure && !threeDSSuccess) {
                const formData = readFormData(formEl, defaults, globalUserOptions, externalFormData, validForm);

                if (!validForm) {
                    sendMessageToAllIframes({ action: 'VALIDATE_FORM', data: formData });
                } else {
                    arrayObjectHelper.assign(threeDSFormData, formData);
                    initCardAuthorization();
                }

                return;
            }

            const formData = readFormData(formEl, defaults, globalUserOptions, externalFormData, false);

            Object.keys(iframeElems).forEach(function(value) {
                sendMessage({ iframeId: value, action: 'PROCESS_FORM', data: formData });
            });
        } catch (error) {
            ChargifyBadger.notify(error);
            throw (error);
        }
    };

    var readFormData = function(formEl, defaults, globalUserOptions, externalFormData, readThreeDSVerificationAmount) {
        var formData = {};

        formEl.querySelectorAll('[data-chargify]').forEach(function(input) {
            var fieldDefault = defaults[globalUserOptions.type][input.getAttribute('data-chargify')];
            if (fieldDefault && !fieldDefault.required) {
                formData[input.getAttribute('data-chargify')] = input.value;
            }
        });

        // When threeDSecure is enabled and form data is being read for 3DS
        // verification, we'll look for a verification amount provided by input.
        if (readThreeDSVerificationAmount) {
            var input = formEl.querySelector('[data-chargify="threeDSVerificationAmount"]');
            if (input) {
                formData[input.getAttribute('data-chargify')] = input.value;
            }
        }

        return { ...formData, ...externalFormData };
    };

    var sendMessageToAllIframes = function(message) {
        Object.keys(iframeElems).forEach(function(iframeIndex) {
            sendMessage({
                iframeId: iframeIndex,
                action: message.action,
                data: message.data
            });
        });
    };

    var sendMessage = function(message) {
        try {
            loggerHelper.info('{chargifyjs} sendMessage', message);
            iframeElems[message.iframeId].contentWindow.postMessage(message, jsHost);
        } catch (error) {
            ChargifyBadger.notify(error);
            throw (error);
        }
    };

    const handleTokenError = (data) => {
        const { gateway } = threeDSConfig;
        const { threeDSData } = data;

        if (threeDSecureHelper.challengeRequested(gateway, threeDSData)) {
            threeDSecureHelper.initChallenge(gateway, threeDSData, onThreeDSSuccess);
        } else {
            tokenFunctions.error(data);
        }
    };

    var receiveMessage = function(event) {
        try {
            var jsHostFragments = jsHost.split('/');
            var allowedOrigin = jsHostFragments[0] + '//' + jsHostFragments[2];

            if (event.origin !== allowedOrigin) {
                loggerHelper.error('{chargifyjs} - message received from another host: ', event.origin + ' ' + allowedOrigin);
                return;
            }

            var message = event.data;
            loggerHelper.info('{chargifyjs} receiveMessage', message);

            switch (message.action) {
                case 'GET_TOKEN_SUCCESS':
                    try {
                        tokenFunctions.success(message.data.token, message.data.message);
                        goCardlessHelper.setGoCardlessDataConfirmed(false);
                    } catch (error) {
                    // ignore error in callback so it won't be send to hb
                    }
                    break;

                case 'GET_TOKEN_ERROR':
                    try {
                        handleTokenError(message.data);
                        goCardlessHelper.setGoCardlessDataConfirmed(false);
                    } catch (error) {
                    // ignore error in callback so it won't be send to hb
                    }
                    break;

                case 'RESIZE_IFRAME':
                    resizeIframe(message.data);
                    break;

                case 'GOCARDLESS_COUNTRY_CHANGE':
                    goCardlessHelper.handleGoCardlessSettingsForCountryCode(message.data);
                    break;

                case 'GOCARDLESS_INIT_TOGGLE_IBAN_OR_LOCAL':
                    goCardlessHelper.handleGoCardlessIntiToggleIbanOrLocal();
                    break;

                case 'GOCARDLESS_DATA_RECEIVED':
                    goCardlessHelper.receivedGoCardlessData(message.data);
                    globalCallbacks.onReceivedGoCardlessConfiguration();
                    break;

                case 'GOCARDLESS_DATA_ERROR':
                    globalCallbacks.onGoCardlessReceiveConfigurationError();
                    break;

                case 'GOCARDLESS_TOGGLE_IBAN_OR_LOCAL_DETAILS':
                    goCardlessHelper.toggleIbanOrLocalDetailsFields();
                    break;

                case 'GOCARDLESS_FORM_DATA_FOR_MODAL':
                    goCardlessHelper.setGoCardlessFormDataForModal(message.data);
                    break;

                case 'APPLE_PAY_DATA_RECEIVED':
                    applePayIframe.receivedApplePayData(message.data);
                    break;

                case 'APPLE_PAY_DATA_ERROR':
                    globalCallbacks.onApplePayError(message.data);
                    break;

                case 'APPLE_PAY_PAYMENT_METHOD_NONCE_RECEIVED':
                    globalCallbacks.onApplePayAuthorized();
                    break;

                case 'PAY_PAL_DATA_RECEIVED':
                    payPalHelper.receivedPayPalData(message.data);
                    break;

                case 'PAY_PAL_DATA_ERROR':
                    globalCallbacks.onPayPalError(message.data);
                    break;

                case 'PAY_PAL_PAYMENT_METHOD_NONCE_RECEIVED':
                    globalCallbacks.onPayPalAuthorized();
                    break;

                case 'THREE_D_SECURE_FORM_DATA':
                    Object.keys(message.data).forEach(function(fieldName) {
                        threeDSFormData[fieldName] = message.data[fieldName];
                    });
                    threeDSSuccess = false;
                    validForm = false;
                    break;

                case 'THREE_D_SECURE_CONFIG_RECEIVED':
                    receivedThreeDSConfig(message.data);
                    break;

                case 'HIDE_IFRAME':
                    goCardlessHelper.hideIframe(message.data);
                    break;

                case 'SHOW_IFRAME':
                    goCardlessHelper.showIframe(message.data);
                    break;

                case 'VALIDATION_SUCCESS':
                    receiveValidationSuccess();
                    break;

                case 'VALIDATION_ERROR':
                    receiveValidationError();
                    break;

                case 'FETCH_THREE_D_SECURE_CONFIG_ERROR':
                    receivedThreeDSConfigError(message.data);
                    break;

                case 'GENERIC_THREE_D_SECURE_CARD_TOKEN':
                    destroyThreeDSecureIframe();
                    setThreeDsToken(message.data);
                    tokenFunctions.token();
                    break;

                case 'REMOVE_GENERIC_THREE_D_SECURE_MODAL_IFRAME':
                    destroyThreeDSecureIframe();
                    break;

                default:
                    loggerHelper.error('{chargifyjs} invalid action: ', message.action);
            }
        } catch (error) {
            ChargifyBadger.notify(error);
            throw (error);
        }
    };

    const internal = f => (...args) => {
        if (hosts.validateInternalAllowedClientHost()) return f(...args);

        throw 'Access forbidden. Chargify._accessInternal is not part of the public interface.';
    };

    this.load = load;
    this.token = token({});
    this.unload = unload;
    this._internal = { token: internal(token) };
    this._accessInternal = internal(() => ({
        token,
        goCardless: goCardlessHelper.internalFunctions,
    }));
}

window.Chargify = Chargify;
