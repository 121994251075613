import defaults from '../config/fieldDefaults';
import arrayObjectHelper from '../helpers/arrayObjectHelper';

var addSelectorToFields = function(userOptions) {
    if (userOptions.selector) {
        Object.keys(userOptions.fields).forEach(function(fieldName) {
            if (!userOptions.fields[fieldName].selector) {
                userOptions.fields[fieldName].selector = userOptions.selector;
            }
        });
    }

    return userOptions;
};

var getPrimaryIframeSelector = function(options) {
    if (options.type === 'card') {
        return options.fields.number.selector;
    }

    if (['apple_pay', 'pay_pal'].includes(options.type)) {
        return options.fields.paymentMethodNonce.selector;
    }

    return options.fields.accountNumber.selector;
};

var isPrimaryIframe = function(options) {
    return options.selfSelector === getPrimaryIframeSelector(options);
};

var shouldAddField = function(fieldName, defaultField, userOptions) {
    if (defaultField.required) {
        return true;
    }

    return userOptions.fields && userOptions.fields[fieldName];
};

var isInlineForm = function(fields, type) {
    if (type !== 'card') return false;

    var length = Object.keys(fields).length;
    if ((length === 3 && fields.number && fields.month && fields.year) ||
        (length === 4 && fields.number && fields.month && fields.year && fields.cvv)) {

        var selectors = [];
        Object.keys(fields).forEach(function(fieldName) {
            if (!arrayObjectHelper.includes(selectors, fields[fieldName].selector)) {
                selectors.push(fields[fieldName].selector);
            }
        });

        return selectors.length === 1;
    }

    return false;
};

var getFieldData = function(fieldName, defaultField, userOptions) {
    var overrides = (userOptions.fields && userOptions.fields[fieldName]) || {};

    return {
        name: fieldName, // Doesn't always match the value recommended by Google - may break autocomplete
        required: defaultField.required ? true : !!overrides.required,
        label: overrides.label || defaultField.label,
        placeholder: overrides.placeholder || defaultField.placeholder,
        message: overrides.message || null,
        maxlength: overrides.maxlength || defaultField.maxlength || false,
        selector: overrides.selector || userOptions.selector,
        style: overrides.style || null,
        autocomplete: overrides.autocomplete || defaultField.autocomplete || null,
        options: defaultField.options || null
    };
};

var getNormalizedOptions = function(userOptions) {
    var defaultFields = defaults[userOptions.type];
    var fields = {};

    // to keep the order of the user, 1st we loop through those fields
    if (userOptions.fields) {
        Object.keys(userOptions.fields).forEach(function(fieldName) {
            fields[fieldName] = getFieldData(fieldName, defaultFields[fieldName], userOptions);
        }, this);
    }

    // then we add the required ones if they are not present yet
    Object.keys(defaultFields).forEach(function(fieldName) {
        if (userOptions.type === 'gocardless' || (!fields[fieldName] && shouldAddField(fieldName, defaultFields[fieldName], userOptions))) {
            if (userOptions.type === 'gocardless' && fieldName === 'country') {
                const scheme = userOptions.scheme || 'sepa'; // sepa is just initial setting, it will be removed
                fields[fieldName] = getFieldData(fieldName, defaultFields[fieldName][scheme], userOptions);
            } else {
                fields[fieldName] = getFieldData(fieldName, defaultFields[fieldName], userOptions);
            }
        }
    }, this);

    userOptions.fields = fields;
    userOptions.inline = isInlineForm(fields, userOptions.type);

    return userOptions;
};

export default {
    getFieldData: getFieldData,
    shouldAddField: shouldAddField,
    getNormalizedOptions: getNormalizedOptions,
    addSelectorToFields: addSelectorToFields,
    getPrimaryIframeSelector: getPrimaryIframeSelector,
    isPrimaryIframe: isPrimaryIframe
};
