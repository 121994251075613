import arrayObjectHelper from '../arrayObjectHelper';
import { includeScripts } from '../includeHelper';
import initApplePay from './init';

const applePayIframeId = 0; // The same as GoCardless', change it?

let context = {};

const init = (ctx) => {
    context = ctx;
};

// Refactor with iframeHelper.createDataIframe?
const createLoadApplePayDataIframe = () => {
    const applePayButtonIframeSelector = document.querySelector(context.primaryIframeSelector);
    const iframe = document.createElement('iframe');

    iframe.style.display = 'none';
    iframe.style.border = 'none';
    iframe.style.height = '0px';
    iframe.style.width = '0px';

    applePayButtonIframeSelector.appendChild(iframe);

    iframe.contentWindow.location.replace(context.iframeSrc);
    iframe.setAttribute('id', 'apple-pay-load-data');

    iframe.onload = function() {
        context.sendMessage({
            iframeId: applePayIframeId,
            action: 'FETCH_APPLE_PAY_DATA',
            data: arrayObjectHelper.assign({}, context.globalUserOptions)
        });
    };

    iframe.contentWindow.document.close();
    context.iframeElems[applePayIframeId] = iframe;
};

const destroyLoadApplePayDataIframe = () => {
    const element = document.querySelector(context.primaryIframeSelector).querySelector('iframe#apple-pay-load-data');
    element.parentNode.removeChild(element);
    context.iframeElems = {};
};

const receivedApplePayData = (data) => {
    const elemForApplePayButton = document.querySelector(context.globalUserOptions.selectorForApplePayButton);
    const applePayButton = document.createElement('button');
    applePayButton.id = 'cfy-apple-pay-button';
    applePayButton.style = '-webkit-appearance: -apple-pay-button; -apple-pay-button-type: plain; -apple-pay-button-style: black; height: 64px; width: 300px; display: none';
    applePayButton.lang = 'eng';

    elemForApplePayButton.appendChild(applePayButton);

    includeScripts(data.braintree_srcs).then(() => {
        const applePayModalOptions = {
            applePayLabel: context.globalUserOptions.applePayLabel,
            applePayAmount: context.globalUserOptions.applePayAmount,
        };
        const applePayCallbacks = {
            paymentMethodNonceReceived: applePayPaymentMethodNonceReceived,
            onApplePayAuthorized: context.globalCallbacks.onApplePayAuthorized,
            onApplePayNotSupported: context.globalCallbacks.onApplePayNotSupported,
            onApplePayError: context.globalCallbacks.onApplePayError,
        };

        initApplePay(context.globalUserOptions.selectorForApplePayButton, data.client_token, applePayModalOptions, applePayCallbacks);
    });

    destroyLoadApplePayDataIframe();
    context.createIframes();
};

const applePayPaymentMethodNonceReceived = (paymentMethodNonce) => {
    context.sendMessageToAllIframes({ action: 'APPLE_PAY_PAYMENT_METHOD_NONCE', data: paymentMethodNonce });
};

export default {
    init,
    createLoadApplePayDataIframe,
    destroyLoadApplePayDataIframe,
    receivedApplePayData,
    applePayPaymentMethodNonceReceived
};
