
var getDataImage = function() {
    var result = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAAAWCAYAAAA2CDmeAAAABGdBTUEAALGPC/xhBQAAACBjSF';
    result += 'JNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QA/wD/AP+gvaeTAAAAB3RJTUUH4wMGCTAJl';
    result += '4RTWAAAFLtJREFUaN7VWmmYVNWZfs+5S93aq7u6eqehaZodkV32REQTFHGL4DZGo46KxqATEjOjxiWamEQxxm1CzGAyakhc';
    result += '4hJwQzEiEm2RHRqa3tfq6tqXu5zzzY9qIEzmR2ZGM898z1NP3efUPee+93vv+y3nFosuu+JSGR36qcxkKiEloHBQtgAIATA';
    result += 'UjQCmquDh0GF1TP2N9r5Dbxf2foCSu38Ip/ko1PGNcJpbAAD67FMBMPhvvgpfpOU2vgpwDnvXfoiuHoi+KHhJCDwcgr3rAM';
    result += 'rff+F/vHb8ljsRWnc3hq68BZAEtbEeICD4/Vs/N/zmJ7tABRNwHDDDADQVrhmnQBXtXfepo0dWepacB23aJEASkt+9H6KjB';
    result += '+D8+AJk2xB90UYQ1nquWrld/agxKxMpn0ykfLCtEIg483oM5vEcApD9QtkYxgPGDSoURlOuoAAAC/gqmWG0Mp/nyP9m7bZH';
    result += '7kHt/sNQRtbWgbMKHvDtkbFEIf3QL+C/9drPB/9QAvbOffCtvYEDoOEPVJnJ1lu79kP09aP0nDPAwyVgun7ybCkBIoBzyFR';
    result += '6pLllmy//+qZs4NabVlI2d0Fh89ZGmUiGuc87yHR9GThv+aIJkQMxgLGA03x0tUykJ8rB2ASntTOsjhl1p7Vj5wP/nbXM7U';
    result += '1gbgOUzUGfNxODZ10KXlUx2drx6Qay7EbnaMePIm89d3/uuT/Q54Y/lQY01RW/as11zO/byPzefgDgkBJwHMhMtighKU+QI';
    result += 'CQgJZSRNdAXzQHzuAEhiJJpuGbPhjpp7Auu0+dfz4P+JsrmSimf91OhABQKXzQf0E6dCG3qxKiMJ9e4L1z2LebzCkpnVMpk';
    result += 'FSqYf/M6ibU/QGHTu+g6dRI+mz8L0SUrIWMJwLRmUCY3ndIZv+yPLsg+vkG1tv35c8PvdHRDDMVLRE//2bJ3wC0HYigScsw';
    result += 'IRRUQAIVDaRgFdco48MoIvFevQun6n8D15bkgRwCMgRkGeCiYaL1zTSfzujNgDAAjgKF4/MUa03Uwt4t8N15pKXU1Q1C4Dc';
    result += 'ZAlk1MUf72dfxeML/XCFx47TdHL7tinTZhTIN2ynjwcMnbPBx6lgcDTUpt1TN9q79uq431nwv25N0PwW7aA9HRM0amMlXQV';
    result += 'HHsN/UEIVRUBWdwfXkuRO8A9NnToE+fgvzLm5D+8RNwDrcO55WicrUp47ENwMWOOD4GEER3L7qD46HNPAWuL8+DHEpAKY9A';
    result += 'ptJgbgPmm+/DatoFmUtCrR0F1+nzUbphHfqnLoU6fgzkUALIF4qqTaTgWrIAztF25N97FVrdVBhLF0KfNxP5F/4I6Dpgmgy';
    result += 'SGIgAy2K8PIzBJd+Ac7gVJCSU8jCYywVt8lg4nb1QR9fB3tcM66NPkX3qN3CdPn+RTKbWirauGjDW7zQffUAZUd1duvHJK7';
    result += 'KP/spIPfxQruaNrcg+sQEDiy8E5QuQiRSgcLjmz4K9az+Y1wPPynMhBofAvR7kX3sb5rsfQlIS2siJcC0+DfFnHkHkzp9Ad';
    result += 'HSj9DeP8uiC8y+hdDbIQwHH3n0QA0tW/jUhvCQEpuvIv7gZzr5muM89C66FpyF+zbdBBRPMbRyfIpMp6ADgOMf4IGa4/CTp';
    result += 'HH3O9EZeEjjKS0u2OHsPpZ29h6COqYc6eqTOwyVfMpYunk9CGABamK690Vs7s107dRK41z2aB3yLKZfn6B8k3jDK1k+btkk';
    result += 'OxWd4z710Ltl2H/O432tm7GD9/mbYn+0DLPt4qCXTkuHfPeXNbvjd2aKjJ075vKmMqK5hfp+fV1X8SSlYB/Q50xTR3j3L9a';
    result += 'W5c5mquCmZnqyES7YwXW9TaqveU+tHTLf3NYeSa38QhCNKPCsu6mQu7QNeElrAS4KjZDzlMK9H02edupOyuTxzu2cyt9HJI';
    result += '+H37d0HbO3MRW7u8843lp0+F45wQ8qDzOXa7KmbNSBjcR9Z9uiBeStWyf7o1VCUpPXxroUynuxX6mr4XxACkCRACNi7DoAx';
    result += 'BtE3CKe9C/r82dCmTYK1rekk6cnoENwAyHaG9aa4RF90LYRsldmsW3T23OUcaX9NGV23hvl9UaW2Us/+6vk7mKqeqlRVvMB';
    result += 'AIXvvoTtES9vN6riGb1rbPn7XWLaklvKpJaKr90uif7CGl4bazHe3T5fJtE7ZnE/Gk98VXb1d4XOvWj0w46tvlPzyJyfHAy';
    result += 'm56Ol3yd7+eqjKFcibZ9j7mpvVuuo3MbK2iVdXlKQffOIuSmVW8fLw75nXc1B09pwG25bq+ManSp784Sfxq2/7DpnWqfaeA';
    result += '0th2gG1cdQbvCy8k4SYK3sHFjptnYvhOJoTCj7FXHoFFQoLxcCgknvu5RX6jFMOZtatf0gmUouU2qpHQbJP9A7cLaKxG43T';
    result += '59/sHD46TsaTl8pE6hQqmAZzG2Bu962K2ygo5WWMn6QQoCjHWLyYB2wblMqAuXQoI2uPn3PMRN8APABgWcfmllEmy8Ivr78';
    result += 'rcPtNt0PX/iwGBi+TPf13hH//rzDf37FADsbXgPMe98XLn9v7/BPrmNfzgUymJ1PBvDf80i8DzNDfD7+4/kpeVf4hhABlcy';
    result += 'NkPOH2XnvZtwLfv3U183s/lrF4g+juuyP4wO1BSqRAhcJxhSgjqit3n71kKP/aOz/ikfAbvKr8Q23K+K+Z25u+7f/OjU3ml';
    result += 'm3fFb39t0DXOjyXnPfP1kef/pyXlz0ts7kGp7VjXeqehyOpXz12n7F47m2MK4NkWZC5fFybNHYo9cwj9/Dq8psBDJEQINM8';
    result += 'HST3yMEhhfKFEKQcXdi05W6nresyZrhe3f/6Mz/OvvncL5Sayg1yKDHH3n3wUV5Z/iFU9SLmNjaAMTCX3u9aOPuqwF23Lvd';
    result += 'ec+lyfpKHiUBCFhP38aw3/F0wTxwfI6SnH75imChOtx0JIV4dmLPc6TlnqclDgY9BBDE4tDJ+3dqJYKyB8gWv6O5bld/4yo';
    result += 'TGmhmggjlQJN/xykRKdV+wDEcYE6K9uwMA4AhihvFW9qlf28ZXT88yt9EFxkCmWeu0dgZkKg1IOp7CmNvw7wXgufS8FZRMn';
    result += '63UVt3gHG5t9v/T9Ug/8PM6OZT4GmwHTFP3TPvHy5NqYz0Y8BkYtymdmWrv3DcleNO3oY4d7WE+jx8AaCgZ7WCG9F98PSiV';
    result += 'KSZMISGjMaGMrH2eV5b/VikrWc9Lglz0D17BOCOltmrbhAXnw3f+N8BLgu8wXcuKwaE5snfgrNz2V3LMcKnDPifR3Zs2/7Q';
    result += 'j7Rw+mioOMgayHVA8CebSwQxX8QY1DTwYgBwcgr3/MMBO5k/29sMDgIYVwlx6QRlR08UjZfBkc2CG0QeFA6ZZLnr6ZymVkS';
    result += '1yKPEaM1xdvKoiaixf2mh/smsGAMh0us/68OMMCPCEJ4Ny+WLt6tLSPBI+ysvDxxxedL2iEA+HiLlcIMc5oV4p2YW33XNe4';
    result += 'Z0PnoTtmPrMU6RSGYGMxgBJOmzbAGOAlPKB4vkgIYrykgSyLEb5AmQ2xyBksaL0GLBhFncvjpWQRABRq/87qw97V2dXq3U1';
    result += 'cvCsyx6gXN7L3EaOGa5OXlGG4cosyvYcyFAu75XpzBmjiH4xuOKqWtHdV8TMOawdn0I7dUr4hIdNC9lf/x6UyUId3wBybCg';
    result += 'ja6BUVyD3mxfgtLQBysmERB+7H2EAVLCGJSNSMjrYLxNJQFXBSwJgjIOEhBxKBAd/9XCLUlP5O7KdemvHzsdEe/cdUJWaIp';
    result += 'kuUhvrNehaUMT6/1K1cSoUohj2GQMDiMA0DUpFhDG3AbJsUNFBkMn0VOuzvd+mRDIs05k6e3/zhfGf3QseLoU6cWwnM4wtA';
    result += 'EBCTj/zwcdL7d0HwDR1Fog0FvDtU0fV7uaVZaBcDnSsJzNccIbJAw2PMQYoStfb4RLbfOt9AQCUzY0AEZiqEi8rdZSKCJwj';
    result += 'bXAOtw6RZceH72ekaOv04z/1SiW/fJg7zS3/csLDCoe55UNk/vXXcK84C57LL4D7omXIPv0cMo9vKIaF/2QN0RjGEyk86A8';
    result += 'dT6mZnFMMYQSybAAEpnAoFWX5kjMvudj+bN9jcig+kfu8D3uuvOgW7vPtAQCmKtLeua/W3n3gphE0oCp1NRXDRAmlpkoodd';
    result += 'XHGCo6g2QeumaB4UTFDYByBU2bPP5OFgp+BMeB6IteHL7jwXKZSGLgG6tMFgr8iZeG2mDZNfkXNz3oWjD7DjEQu4T7fVu1C';
    result += 'Y3fij3+QA/3eE5qkpmuQwCgglkcpyIGHi6xPQB4WWkRmMJl8YAIQhQJZQxgxSZtGKIFXRfHwvxx91eXMyqYkROESAl97nTI';
    result += 'WBype9bBbtqD7OPPIPPI06BM7r9s9shxAEDnQX9keGvFo4yoDimRUsihJOTgkEZCApo2pNRWtctY/BYyLZ9SXbm5/c1nt3p';
    result += 'Wnps63lUTQEIyEI0WHd1u7vOEAIBpKlNqKqFUVWD4yS7CTWUGC5u2pJ2OHpBpFp9cxsBLQ02dj9zzjlJe9jw0DZRKT7Wa9i';
    result += 'y1PvoUoatunQIpV+izpq7Qpk8+g5eGXuOlwTZtQuMN3mtWLZfpzNbR2RxkIgVKZYqEsGJIIQCUyYEyWTqmVtkX7RiFarjmz';
    result += 'UQ7Y2Bez34oHLAdRcYSbtndByVSCiUSDjNVKQFnYG5jt1JdkSbT+iuHMk2jv1CIAuPsJaBkGqKzGzKTBQ8FoU4cCx4p/asK';
    result += 'CyjuJ8lorJjUOQek9FM8UUumhTUDuwDCFIDAS4KvKyOqu8l26ocvnK5gtUjduy4kk6k6EIGk1MGgMc5Vpa5GUq4gh0kmXlY';
    result += 'iKJ3Bx4wRLJuKT60m9elTHKZrACBBwzrhjNwA1DGjXuZ+7wEyLUXG4l+PvPs7XSZSk2Q0Nll09c4HYxMhZVBmcnWis3th4Z';
    result += '1t1yqR8JTXvR5QNgfR3SuKCmcAIIOohjKiGkp9nRsK1wGAbCevz52J/B/egH/VjVAqy1/nXm8P2Y6bMtnxTkc37IMtED19D';
    result += 'VQwS5nbHVcqyp77jDEiy+LFCHYskoDINP+iDwGBBwMI/vQuiK5ecJ8XzO8DOIfo7Ufm0adR+MObJxEi+gYAIUC2o/Ky0g/B';
    result += '4BJ90StcSxZ8+sTlNzfYu/afyUtCO9SGUfcyXe8HY60gqhL90VmeKy6cajftWcE8bgfJNGDbE0R33+VMU5FY/b2pMpUeAQB';
    result += 'kWoHCK2/NVOpqDjasvCFiHzxSN0xUhdW0Z742aWyn/eneiVQwixVRLl8euf2BmUokTPbu/YdkPDlBxuILYxdddylzu0zKm2';
    result += 'X2oZbHWUs7ke0wkCyGPN4D4fN0zlt14xpeGtpl728+DbbtBxEokx3jvf4fpsI0szKbPweMlYIIEGKs2lg/jSw7KmPxrrI3n';
    result += '90dXXTBPdRtPiR6+i9xn3PGK7w0ZOVf2nwDOJc8Er6v9Pkn3u/47ZPgwUC7HIwDthOBpBnxq24tY5pWrtwWrP4+CICQoFwO';
    result += 'xpmLoY4aAebSANsBFA61thr6lAkovLkVMpON8aD/38BYVj9lPGQqrTp7D83mbuMxdUT10zKbnYJMbhWl0otAeEufNul20dL';
    result += 'WUnhps6lOGNPJOB9D6WwpHGcuwA5pUyfcD9P0ykyuApbFlMryXU5r5wpGkDxc0so8Ri9MazwYC8hYYgXTNYVHwq3M447Ddq';
    result += 'Ywj7tO9vYvZro+xEuCR0kIizlipkyk5oloTOUedwfT9XbKFxqYpqV4SaCPYvFp5Agww5XmAX+OGUYBJEGZXBiMjWGqWk3Z7';
    result += 'GwWCvbwSPgoU1XAshvIEfWUSs1lHncbDwVamOHyUi43F0Ja6Y1P7saQhPey83c6bV2HyXamy3hymdPWtQIF06fW1dztvfJr';
    result += '67NP/UYGxk6F1li/nyzbC8epgeMsJsuayP2+Z08ohDPA0CGig8hu2Ahz60fFOKqq0GdMhu/mb0AZWQPRe6ICYj4fZDpj6rO';
    result += 'n3sYrIpnCH7eIyHu/vyn1vR+V8aBfbPmXW2IL61fDaT6Kqp4mHNL1t2oe+eWf8y9vNrTxY0To8ftjsYuuo8Cda67P/tvGEq';
    result += 'W8LEemmWNej+b9+tdInzcTlMkh/+pbzGlukcr8Wdy9cjnxgB+UySL/0iYmE2nSJo5l7guXEQv6Yb6zjZlbt4NyebiXL4V+2';
    result += 'gyiVAa5f3+ReHl4gvXJ7uXM621TwqEfqvV1Teq4BpssC86BI2F736E1lEhNYR73w6Enf9SlNoxkACA6e5B/4Y9gPi+5Fs7m';
    result += '6rgGgpAw3/uQ5Ta+CkqmBADIoTgKW7ZJa8fOjcEf3r7JOXR0JIi4OmZUe/zG25Pq2NEg24FzqAVQ1Z7IO7+9KfX9h2plMhW';
    result += 'CEL2JR+8bOEGIpsJ75cWQfVFkHl5f3HrnxRLTOXQE+pzpoFy+GFOPpRNdG+4LWFL2D8K1YDZyTz8vlaryAeY2cOaLmyATSY';
    result += 'SffQzWgSMYR4T0g08koapJFvDBfOcDuBbNgbVzrw1FGSAiMLcBZsB02jqLby8dAQgHPBQEOIezrxm8IgLZNwBIAvO6QaYFe';
    result += '9d+QNch+6PgAT/I6yne/IEjoHweztF2qAqfJwdiC5S66nWF995+ytd4DeDSAceB6O6FUhmplKlMjTKyJlXY/K5tLF0Eputw';
    result += 'WjuGcbkg+gYg01nAsiB6B8CDfiDgg2bUI/zvP4e5Yyc++tm9WGDaaeZx7y32ORK11A3rz5/BNWcaACB72z8i/9JmAYZ25nK';
    result += '1M1VBbUsbWE/dbIIQABG8114K9/lfQWLtvXAOtQJSguk69NlToc+bicwjTwOMNWuTxi0CZ/1lL67H/xeLLr0EvDw8yWras5';
    result += 'G59JjaWH+N+5wz2l2nz6fs+ue4vffgaBlL3Md8nu29rz/z47Fbt8NYPPfvjpP1T/9Kn+gbKNaUugbjjAXQJo+DTKZBtg2m6';
    result += '6BMtpg/BoagVFe86rv56pWibyAf+M7q/2s//83WAiB0xTdBpjVV9vbfAM4reCiYYV43l6mMA8tSmGH8yX3xOb8W7V05ZeQI';
    result += 'eC+/4O+Okw1edN1l4mjHPTKRHE2OAwhZ3D7x+8BUpbjZmM6AKQp4aeiwOm7MtU5b59bc9lfw+byu+fvY4HlXY//LT2PcjK+';
    result += 'g/JNNLHHj96rAUEeWrTPDleFlpa2pH/wsHrxvLahgInjX5/eHhv+O/QcGcLAIeBco6gAAACV0RVh0ZGF0ZTpjcmVhdGUAMj';
    result += 'AxOS0wMy0wNlQwODo0ODowOSswMTowMPD8MO8AAAAldEVYdGRhdGU6bW9kaWZ5ADIwMTktMDMtMDZUMDg6NDg6MDkrMDE6M';
    result += 'DCBoYhTAAAAEnRFWHRTb2Z0d2FyZQBlemdpZi5jb22gw7NYAAAAK3RFWHRDb21tZW50AFJlc2l6ZWQgb24gaHR0cHM6Ly9l';
    result += 'emdpZi5jb20vcmVzaXplQmmNLQAAAABJRU5ErkJggg==';

    return result;
};

export default {
    getDataImage: getDataImage
};
