const store = {
    reference: null,
    formData: null,
};

// API over mutable global state
const accessStore = {
    fetchFormData: () => store.formData,
    fetchReference: () => store.reference,
    saveFormData: (formData) => {
        store.formData = formData;
    },
    saveReference: (reference) => {
        store.reference = JSON.stringify({ reference_id: reference });
    },
};

export default accessStore;
