import creditCardType from 'credit-card-type';

var isNumberLuhnValid = (function(arr) {
    return function(value) {
        var number = getCleanCardNumber(value);
        var len = number.length;
        var bit = 1;
        var sum = 0;
        var val;

        while (len) {
            val = parseInt(number.charAt(--len), 10);
            /* eslint-disable-next-line no-cond-assign */
            sum += (bit ^= 1) ? arr[val] : val;
        }

        return sum && sum % 10 === 0;
    };
}([0, 2, 4, 6, 8, 1, 3, 5, 7, 9]));

var getCardType = function(value) {
    return creditCardType(getCleanCardNumber(value));
};

var getCleanCardNumber = function(value) {
    if (!value) return '';

    return (value || '').replace(/[^\d]/g, '');
};

export default {
    isNumberLuhnValid: isNumberLuhnValid,
    getCardType: getCardType,
    getCleanCardNumber: getCleanCardNumber
};
