var CHARGIFY_JS_VERSION = '2020-12-03';
var CHARGIFY_JS_SHA = '631a29ebbe58456d1594dde5d235cbb45682b718';

export default {
    // turn on or off the console logs from the app
    debug: false,
    version: CHARGIFY_JS_VERSION,

    server: {
        // path where static assets are located
        // jsHost: 'https://js.chargify.com/v/' + CHARGIFY_JS_VERSION,

        // path for the HTML of each iframe (i.e. '/path/to/html')
        iframePath: '/hosted-field.html',

        // path for the API to the get token (i.e. '/path/to/token')
        tokenPath: '/js/tokens.json'
    },

    // these numbers will be valid for credit card number (string)
    creditCardTestNumbers: ['1', '2', '3'],

    Honeybadger: {
        apiKey: '267a513a',
        revision: CHARGIFY_JS_VERSION,
        onerror: false,
        onunhandledrejection: false,
    }
};
