import config from '../config/config';

var log = function(message, value) {
    if (config.debug) {
        console.log(message, value || ''); // eslint-disable-line no-console
    }
};

var info = function(message, value) {
    if (config.debug) {
        console.info(message, value || '');
    }
};

var warn = function(message, value) {
    if (config.debug) {
        console.warn(message, value || '');
    }
};

var error = function(message, value) {
    if (config.debug) {
        console.error(message, value || '');
    }
};

export default {
    log: log,
    info: info,
    warn: warn,
    error: error
};
