import arrayHelper from './arrayObjectHelper';

const getIframeSelectors = ({ fields }) => {
    let selectors = [];

    Object.keys(fields).forEach((fieldName) => {
        let selector = fields[fieldName].selector;
        if (!arrayHelper.includes(selectors, selector)) {
            selectors.push(selector);
        }
    });

    return selectors;
};

const getIframeName = (type, selector) => {
    return '%' + type + '%' + selector;
};

const isNameIncludesType = (name, type) => {
    return name && name.includes('%' + type + '%');
};

const dataIframeStyles = {
  display: 'none',
  border: 'none',
  height: '0px',
  width: '0px',
};

const createDataIframe = (primaryIframeSelector, src, id, iframeStyles = dataIframeStyles) =>
    new Promise((resolve) => {
        const iframeSelector = document.querySelector(primaryIframeSelector);
        const iframe = document.createElement('iframe');

        Object.keys(iframeStyles).forEach((styleProperty) => {
            iframe.style[styleProperty] = iframeStyles[styleProperty];
        });

        iframeSelector.appendChild(iframe);

        iframe.contentWindow.location.replace(src);
        iframe.setAttribute('id', id);
        iframe.name = id;
        iframe.onload = () => resolve(iframe);
        iframe.contentWindow.document.close();
    });

const destroyDataIframe = (primaryIframeSelector, id, iframeElems, iframeId) => {
    const iframe = document.querySelector(primaryIframeSelector).querySelector(`iframe#${id}`);
    if (!iframe) return;

    iframe.parentNode.removeChild(iframe);
    delete iframeElems[iframeId];
};

export default {
    getIframeSelectors,
    getIframeName,
    isNameIncludesType,
    createDataIframe,
    destroyDataIframe,
};
