/* globals braintree paypal*/

import arrayObjectHelper from '../arrayObjectHelper';
import iframeHelper from '../iframeHelper';
import { includeScripts } from '../includeHelper';
import loggerHelper from '../loggerHelper';

const payPalIframeId = 0;
let context = {};

const init = (ctx) => {
    context = ctx;
};

const initPayPal = (braintreeToken) => {
    braintree.client.create({
        authorization: braintreeToken
    }).then((clientInstance) => {
        return braintree.paypalCheckout.create({ client: clientInstance });
    }).then((paypalCheckoutInstance) => {
        return paypalCheckoutInstance.loadPayPalSDK({ vault: true });
    }).then((paypalCheckoutInstance) => {
        return paypal.Buttons({ // eslint-disable-line new-cap
            fundingSource: paypal.FUNDING.PAYPAL,
            createBillingAgreement: () => {
                return paypalCheckoutInstance.createPayment({
                    flow: 'vault',
                    enableShippingAddress: true,
                });
            },
            onApprove: (data) => {
                return paypalCheckoutInstance.tokenizePayment(data).then((payload) => {
                    hidePayPalButton();
                    payPalPaymentMethodNonceAndEmailReceived(payload.nonce, payload.details.email);
                });
            },
            onError: (err) => {
                context.globalCallbacks.onPayPalError(err);
                loggerHelper.error('PayPal error:', err);
            }
        }).render(context.globalUserOptions.selectorForPayPalButton);
    }).catch((err) => {
        context.globalCallbacks.onPayPalError(err);
        loggerHelper.error('PayPal error:', err);
    });
};

const hidePayPalButton = () => {
    document.querySelector(context.globalUserOptions.selectorForPayPalButton).style.display = 'none';
};

const createLoadPayPalDataIframe = () => {
    iframeHelper.createDataIframe(context.primaryIframeSelector, context.iframeSrc, 'pay-pal-load-data')
        .then((iframe) => {
            context.iframeElems[payPalIframeId] = iframe;

            context.sendMessage({
                iframeId: payPalIframeId,
                action: 'FETCH_PAY_PAL_DATA',
                data: arrayObjectHelper.assign({}, context.globalUserOptions)
            });
        });
};

const destroyLoadPayPalDataIframe = () => {
    const element = document.querySelector(context.primaryIframeSelector).querySelector('iframe#pay-pal-load-data');
    element.parentNode.removeChild(element);
    context.iframeElems = {};
};

const receivedPayPalData = (data) => {
    includeScripts(data.braintree_srcs).then(() => {
        initPayPal(data.client_token);
    });
    destroyLoadPayPalDataIframe();
    context.createIframes();
};

const payPalPaymentMethodNonceAndEmailReceived = (nonce, email) => {
    context.sendMessageToAllIframes({ action: 'PAY_PAL_PAYMENT_METHOD_NONCE_AND_EMAIL', data: { nonce, email } });
};

export default {
    init,
    createLoadPayPalDataIframe,
    receivedPayPalData,
};
