import arrayHelper from './arrayObjectHelper';
import defaults from '../config/fieldDefaults';
import hosts from '../validators/hosts';

var validateOptions = function(userOptions) {
    var errors = [];
    const supportedTypes = ['card', 'bank', 'gocardless', 'apple_pay', 'pay_pal'];

    if (!validateSelector(userOptions)) {
        errors.push('`selector` key is required either in the root of the options or on every field. I.e. "#card-form".');
    }

    if (!userOptions.publicKey) {
        errors.push('`publicKey` key is required.');
    }

    if (!supportedTypes.includes(userOptions.type)) {
        errors.push('`type` key is required. Possible values: ' + supportedTypes.join(',') + '.');
    }

    if (userOptions.type !== 'gocardless') {
        if (userOptions.selectorForGoCardlessHeader) {
            errors.push('`selectorForGoCardlessHeader` is only supported for type = `gocardless`');
        }

        if (userOptions.selectorForGoCardlessFooter) {
            errors.push('`selectorForGoCardlessFooter` is only supported for type = `gocardless`');
        }

        if (userOptions.selectorForToggleIbanOrLocalDetails) {
            errors.push('`selectorForToggleIbanOrLocalDetails` is only supported for type = `gocardless`');
        }

        if (userOptions.customGoCardlessModalStyles) {
            errors.push('`customGoCardlessModalStyles` is only supported for type = `gocardless`');
        }
    }

    if (userOptions.type === 'apple_pay') {
        if (!userOptions.selectorForApplePayButton) {
            errors.push('`selectorForApplePayButton` key is required.');
        }

        if (!userOptions.applePayLabel) {
            errors.push('`applePayLabel` key is required.');
        }

        if (!userOptions.applePayAmount) {
            errors.push('`applePayAmount` key is required.');
        }
    }

    if (userOptions.type === 'pay_pal') {
        if (!userOptions.selectorForPayPalButton) {
            errors.push('`selectorForPayPalButton` key is required.');
        }
    }

    if (userOptions.type && !validateFields(userOptions)) {
        errors.push('One or more of the field names are invalid.');
    }

    if (!userOptions.serverHost) {
        errors.push('`serverHost` key is required.');
    } else if (!hosts.validateServerHost(userOptions.serverHost)) {
        errors.push('`serverHost` key is invalid.');
    }

    if (userOptions.type && userOptions.fields && Object.keys(userOptions.fields).length > 0) {
        var allFieldsOfGivenType = defaults[userOptions.type];

        var allRequiredFieldNamesOfGivenType = Object.keys(allFieldsOfGivenType).filter(function(fieldName) {
            if (userOptions.type === 'gocardless') {
                if ((fieldName === 'phone' || fieldName === 'email') && userOptions.scheme !== 'bacs_nz') {
                    return false;
                } else if (fieldName === 'swedishIdentityNumber' && userOptions.scheme !== 'autogiro') {
                    return false;
                } else if (fieldName === 'danishIdentityNumber' && userOptions.scheme !== 'betalingsservice') {
                    return false;
                } else {
                    return allFieldsOfGivenType[fieldName].required;
                }
            } else if (userOptions.type === 'apple_pay' && fieldName === 'paymentMethodNonce') {
                return false;
            } else if (userOptions.type === 'pay_pal' && ['paymentMethodNonce', 'payPalEmail'].includes(fieldName)) {
                return false;
            } else {
                return allFieldsOfGivenType[fieldName].required;
            }
        });

        var isValid = allRequiredFieldNamesOfGivenType.every(function(fieldName) {
            return arrayHelper.includes(Object.keys(userOptions.fields), fieldName);
        });

        if (!isValid) {
            errors.push('We are missing required fields: ' + allRequiredFieldNamesOfGivenType.join(', '));
        }
    }

    return { result: errors.length === 0, errors: errors };
};

var validateSelector = function(userOptions) {
    if (userOptions.selector) {
        return true;
    }

    var fields = userOptions.fields;

    if (!fields || !Object.keys(fields).length) {
        return false;
    }

    return Object.keys(fields).every(function(fieldName) {
        return fields[fieldName].selector;
    });
};

var validateFields = function(userOptions) {
    var fields = userOptions.fields;
    var type = userOptions.type;

    if (!fields || !Object.keys(fields).length) {
        return true;
    }

    if (!type) {
        return false;
    }

    return Object.keys(fields).every(function(fieldName) {
        return arrayHelper.includes(Object.keys(defaults[type]), fieldName);
    });
};

export default {
    validateOptions: validateOptions,
    validateSelector: validateSelector,
    validateFields: validateFields
};
