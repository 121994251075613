/* global AdyenCheckout */

import { includeScripts, includeStylesheet } from '../includeHelper';
import stylesForAdyenModal from '../stylesForAdyenModal';
import tingle from 'tingle.js';

let environment;

const collectBrowserInfo = () => {
  const screenWidth = window && window.screen ? window.screen.width : '';
  const screenHeight = window && window.screen ? window.screen.height : '';
  const colorDepth = window && window.screen ? window.screen.colorDepth : '';
  const userAgent = window && window.navigator ? window.navigator.userAgent : '';
  const javaEnabled = window && window.navigator ? navigator.javaEnabled() : false;
  const language = window && window.navigator ? window.navigator.language || window.navigator.browserLanguage : '';
  const d = new Date();
  const timeZoneOffset = d.getTimezoneOffset();

  const browserInfo = {
    screenWidth,
    screenHeight,
    colorDepth,
    userAgent,
    timeZoneOffset,
    language,
    javaEnabled,
  };

  return browserInfo;
};

const init = (config) => {
  environment = config.environment;

  includeScripts(["https://checkoutshopper-live.adyen.com/checkoutshopper/sdk/3.6.0/adyen.js"])
    .then(includeStylesheet("https://checkoutshopper-live.adyen.com/checkoutshopper/sdk/3.6.0/adyen.css"))
    .then(stylesForAdyenModal.injectToPage);
};

const createAdyenContainer = () => {
  if (document.querySelector('#adyen-three-ds-container')) return;
  const tag = document.createElement('div');
  tag.id = 'adyen-three-ds-container';
  document.getElementsByTagName('body')[0].appendChild(tag);
};

const startAuthorization = (callbacks) => {
  callbacks.onSuccess(JSON.stringify({ browserInfo: collectBrowserInfo(), origin: window.location.origin }));
};

const showChallengeModal = (action, onThreeDSSuccess) => {
  const modal = new tingle.modal({ // eslint-disable-line new-cap
    footer: true,
    stickyFooter: false,
    closeMethods: ['overlay', 'button', 'escape'],
    closeLabel: 'Close',
    onClose: () => {
      document.querySelectorAll('.tingle-modal').forEach((elem) => elem.remove());
      onThreeDSSuccess(
        JSON.stringify({ browserInfo: collectBrowserInfo(), origin: window.location.origin }),
        { skipCallingTokenFunction: true }
      );
    },
  })

  const configuration = {
    locale: "en_US",
    environment: environment,
    onAdditionalDetails: handleOnAdditionalDetails(onThreeDSSuccess, modal)
  };

  const checkout = new AdyenCheckout(configuration);
  openChallengeModal(checkout, action, modal);
}

const openChallengeModal = (checkout, action, modal) => {
  createAdyenContainer();
  modal.setContent(document.querySelector('#adyen-three-ds-container'));
  modal.open();
  checkout.createFromAction(action).mount('.tingle-modal-box__content');
};

const handleOnAdditionalDetails = (onThreeDSSuccess, modal) => (state) => {
  const details = state.data.details;
  const paymentData = state.data.paymentData;
  const serializedData = JSON.stringify({
    details: details,
    paymentData: paymentData,
    browserInfo: collectBrowserInfo(),
    origin: window.location.origin,
  });

  modal.close();
  onThreeDSSuccess(serializedData);
};

export default {
  init,
  showChallengeModal,
  startAuthorization,
}
