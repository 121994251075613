
// since we can't use lodash and we need to support IE10,
// we have to implement this functions ourselves

var includes = function(arr, item) {
    var found = false;

    arr.forEach(function(eachItem) {
        if (eachItem === item) {
            found = true;
        }
    });

    return found;
};

var assign = function(target) {
    if (target === null) {
        throw new TypeError('Cannot convert undefined or null to object');
    }

    var to = Object(target);

    for (var index = 1; index < arguments.length; index++) {
        var nextSource = arguments[index];

        if (nextSource !== null) {
            for (var nextKey in nextSource) {
                if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
                    to[nextKey] = nextSource[nextKey];
                }
            }
        }
    }

    return to;
};

var getNestedObject = (nestedObj, pathArr) => {
    return pathArr.reduce((obj, key) =>
        (obj && obj[key] !== 'undefined') ? obj[key] : undefined, nestedObj);
};

export default {
    includes,
    assign,
    getNestedObject,
};
