import arrayObjectHelper from './arrayObjectHelper';

let includedScripts = [];
let includedStylesheets = [];

const include = (includedItems, buildTag) => (url) => {
    return new Promise((resolve) => {
        if (arrayObjectHelper.includes(includedItems, url)) { resolve(); }

        const tag = buildTag(resolve, url);
        document.getElementsByTagName('head')[0].appendChild(tag);
        includedItems.push(url);
    });
};

export const includeScript = include(includedScripts, (resolve, url) => {
    const tag = document.createElement('script');
    tag.type = 'text/javascript';
    tag.src = url;
    tag.onload = resolve;

    return tag;
});

export const includeStylesheet = include(includedStylesheets, (resolve, url) => {
    const tag = document.createElement('link');
    tag.rel  = 'stylesheet';
    tag.type = 'text/css';
    tag.href = url;
    tag.onload = resolve;

    return tag;
});

export const includeScripts = (urls) => {
    const scriptLoadPromises = urls.map(includeScript);
    return Promise.all(scriptLoadPromises);
};
