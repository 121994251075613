
var styles = '.tingle-modal * {';
styles += '  box-sizing: border-box;';
styles += '}';
styles += '.tingle-modal {';
styles += '  position: fixed;';
styles += '  top: 0;';
styles += '  right: 0;';
styles += '  bottom: 0;';
styles += '  left: 0;';
styles += '  z-index: 1100;';
styles += '  display: -ms-flexbox;';
styles += '  display: flex;';
styles += '  visibility: hidden;';
styles += '  -ms-flex-direction: column;';
styles += '  flex-direction: column;';
styles += '  -ms-flex-align: center;';
styles += '  align-items: center;';
styles += '  overflow: hidden;';
styles += '  -webkit-overflow-scrolling: touch;';
styles += '  background: rgba(0, 0, 0, .8);';
styles += '  opacity: 0;';
styles += '  -webkit-user-select: none;';
styles += '  -moz-user-select: none;';
styles += '  -ms-user-select: none;';
styles += '  user-select: none;';
styles += '  cursor: pointer;';
styles += '  transition: transform .2s ease;';
styles += '}';
styles += '.tingle-modal--confirm .tingle-modal-box {';
styles += '  text-align: center;';
styles += '}';
styles += '.tingle-modal--noOverlayClose {';
styles += '  cursor: default;';
styles += '}';
styles += '.tingle-modal--noClose .tingle-modal__close {';
styles += '  display: none;';
styles += '}';
styles += '.tingle-modal__close {';
styles += '  position: fixed;';
styles += '  top: 10px;';
styles += '  right: 28px;';
styles += '  z-index: 1100;';
styles += '  padding: 0;';
styles += '  width: 5rem;';
styles += '  height: 5rem;';
styles += '  border: none;';
styles += '  background-color: transparent;';
styles += '  color: #f0f0f0;';
styles += '  font-size: 6rem;';
styles += '  font-family: monospace;';
styles += '  line-height: 1;';
styles += '  cursor: pointer;';
styles += '  transition: color .3s ease;';
styles += '}';
styles += '.tingle-modal__closeLabel {';
styles += '  display: none;';
styles += '}';
styles += '.tingle-modal__close:hover {';
styles += '  color: #fff;';
styles += '}';
styles += '.tingle-modal-box {';
styles += '  position: relative;';
styles += '  -ms-flex-negative: 0;';
styles += '  flex-shrink: 0;';
styles += '  margin-top: auto;';
styles += '  margin-bottom: auto;';
styles += '  width: 60%;';
styles += '  border-radius: 4px;';
styles += '  background: #fff;';
styles += '  opacity: 1;';
styles += '  cursor: auto;';
styles += '  transition: transform .3s cubic-bezier(.175, .885, .32, 1.275);';
styles += '  -ms-transform: scale(.8);';
styles += '  transform: scale(.8);';
styles += '}';
styles += '.tingle-modal-box__content {';
styles += '  padding: 3rem 3rem 0 3rem;';
styles += '}';
styles += '.tingle-modal-box__footer {';
styles += '  padding: 1.5rem 2rem;';
styles += '  width: auto;';
styles += '  border-bottom-right-radius: 4px;';
styles += '  border-bottom-left-radius: 4px;';
styles += '  cursor: auto;';
styles += '}';
styles += '.tingle-modal-box__footer::after {';
styles += '  display: table;';
styles += '  clear: both;';
styles += '  content: "";';
styles += '}';
styles += '.tingle-modal-box__footer--sticky {';
styles += '  position: fixed;';
styles += '  bottom: -200px;';
styles += '  z-index: 10001;';
styles += '  opacity: 1;';
styles += '  transition: bottom .3s ease-in-out .3s;';
styles += '}';
styles += '.tingle-enabled {';
styles += '  position: fixed;';
styles += '  right: 0;';
styles += '  left: 0;';
styles += '  overflow: hidden;';
styles += '}';
styles += '.tingle-modal--visible .tingle-modal-box__footer {';
styles += '  bottom: 0;';
styles += '}';
styles += '.tingle-enabled .tingle-content-wrapper {';
styles += '  filter: blur(8px);';
styles += '}';
styles += '.tingle-modal--visible {';
styles += '  visibility: visible;';
styles += '  opacity: 1;';
styles += '}';
styles += '.tingle-modal--visible .tingle-modal-box {';
styles += '  -ms-transform: scale(1);';
styles += '  transform: scale(1);';
styles += '}';
styles += '.tingle-modal--overflow {';
styles += '  overflow-y: scroll;';
styles += '  padding-top: 8vh;';
styles += '}';
styles += '.tingle-btn {';
styles += '  display: inline-block;';
styles += '  margin: 0 .5rem;';
styles += '  padding: 1rem 2rem;';
styles += '  border: none;';
styles += '  background-color: grey;';
styles += '  box-shadow: none;';
styles += '  color: #fff;';
styles += '  vertical-align: middle;';
styles += '  text-decoration: none;';
styles += '  font-size: inherit;';
styles += '  font-family: inherit;';
styles += '  line-height: normal;';
styles += '  cursor: pointer;';
styles += '  transition: background-color .4s ease;';
styles += '}';
styles += '.tingle-btn--primary {';
styles += '  background-color: #47C486;';
styles += '}';
styles += '.tingle-btn--danger {';
styles += '  background-color: #e74c3c;';
styles += '}';
styles += '.tingle-btn--default {';
styles += '  background-color: #fff;';
styles += '  color: #000;';
styles += '  border: 1px solid #ccc;';
styles += '}';
styles += '.tingle-btn--pull-left {';
styles += '  float: left;';
styles += '}';
styles += '.tingle-btn--pull-right {';
styles += '  float: right;';
styles += '}';
styles += '@media (max-width : 540px) {';
styles += '  .tingle-modal {';
styles += '    top: 0px;';
styles += '    display: block;';
styles += '    padding-top: 60px;';
styles += '    width: 100%;';
styles += '  }';
styles += '  .tingle-modal-box {';
styles += '    width: auto;';
styles += '    border-radius: 0;';
styles += '  }';
styles += '  .tingle-modal-box__content {';
styles += '    overflow-y: scroll;';
styles += '  }';
styles += '  .tingle-modal--noClose {';
styles += '    top: 0;';
styles += '  }';
styles += '  .tingle-modal--noOverlayClose {';
styles += '    padding-top: 0;';
styles += '  }';
styles += '  .tingle-modal-box__footer .tingle-btn {';
styles += '    display: block;';
styles += '    float: none;';
styles += '    margin-bottom: 1rem;';
styles += '    width: 100%;';
styles += '  }';
styles += '  .tingle-modal__close {';
styles += '    top: 0;';
styles += '    right: 0;';
styles += '    left: 0;';
styles += '    display: block;';
styles += '    width: 100%;';
styles += '    height: 60px;';
styles += '    border: none;';
styles += '    background-color: #2c3e50;';
styles += '    box-shadow: none;';
styles += '    color: #fff;';
styles += '    line-height: 55px;';
styles += '  }';
styles += '  .tingle-modal__closeLabel {';
styles += '    display: inline-block;';
styles += '    vertical-align: middle;';
styles += '    font-size: 1.5rem;';
styles += '    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;';
styles += '  }';
styles += '  .tingle-modal__closeIcon {';
styles += '    display: inline-block;';
styles += '    margin-right: .5rem;';
styles += '    vertical-align: middle;';
styles += '    font-size: 4rem;';
styles += '  }';
styles += '}';
styles += '@supports ((-webkit-backdrop-filter: blur(12px)) or (backdrop-filter: blur(12px))) {';
styles += '  .tingle-modal {';
styles += '    -webkit-backdrop-filter: blur(20px);';
styles += '    backdrop-filter: blur(20px);';
styles += '  }';
styles += '  @media (max-width : 540px) {';
styles += '    .tingle-modal {';
styles += '      -webkit-backdrop-filter: blur(8px);';
styles += '      backdrop-filter: blur(8px);';
styles += '    }';
styles += '  }';
styles += '  .tingle-enabled .tingle-content-wrapper {';
styles += '    filter: none;';
styles += '  }';
styles += '}';
styles += '.tingle-modal table.gc_info {';
styles += '  width: 100%;';
styles += '  margin-bottom: 10px;';
styles += '  border: 1px solid #ccc;';
styles += '}';
styles += '.tingle-modal table.gc_info td {';
styles += '  padding: 10px;';
styles += '}';
styles += '.tingle-modal table.gc_info td.label {';
styles += '  width: 35%;';
styles += '  font-weight: bold;';
styles += '}';
styles += '.tingle-modal p.agreement {';
styles += '  margin-bottom: 10px;';
styles += '}';
styles += '.tingle-modal #mandate-details {';
styles += '  margin-bottom: 10px;';
styles += '  font-weight: bold;';
styles += '}';

var injectToPage = function() {
    var styleNode = document.createElement('style');
    styleNode.type = 'text/css';

    var styleText = document.createTextNode(styles);
    styleNode.appendChild(styleText);
    document.getElementsByTagName('head')[0].appendChild(styleNode);
};

export default {
    injectToPage: injectToPage,
    styles: styles
};
