const authorize = function(formData, config, callbacks) {
    callbacks.initGenericThreeDSecureModal(formData, config.store_card_init_url);
};

const iframeStyles = function() {
    return {
        position: 'fixed',
        top: '0;',
        left: '0',
        bottom: '0',
        right: '0',
        height: '100%',
        width: '100%',
        padding: '0',
        overflow: 'hidden',
        margin: '0'
    };
};

export default {
    authorize: authorize,
    iframeStyles: iframeStyles
};
