import Honeybadger from 'honeybadger-js';
import config from '../config/config';

Honeybadger.configure(config.Honeybadger);

const isLocalhost = () => {
    return Boolean(
        window.location.hostname === 'js.chargify.test' ||
        window.location.hostname === 'localhost' ||
        // [::1] is the IPv6 localhost address.
        window.location.hostname === '[::1]' ||
        // 127.0.0.1/8 is considered localhost for IPv4.
        window.location.hostname.match(
            /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
        )
    );
};

const shouldSendException = () => !isLocalhost();

export default {
    notify(error) {
        if (shouldSendException()) {
            Honeybadger.notify(error);
        }
    }
};
