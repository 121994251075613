/* global Cardinal */

import {
  configurationData,
  setupData,
  orderData,
  continueData,
  validateForm,
  validationCancelled,
  validationSucceeded,
  getAccountNumber,
  getErrorDescription,
} from './cardinalData';
import store from './store';
import loggerHelper from '../loggerHelper';

let threeDSConfigured = false;

const init = (config, callbacks) => {
    if (!threeDSConfigured) {
        configureCardinal(config, callbacks);
    }

    initCardinal(config);
};

const configureCardinal = (config, { onSuccess, onError }) => {
    Cardinal.configure(configurationData);

    Cardinal.on('payments.validated', function(data, jwt) {
        if (validationCancelled(data)) return;

        if (validationSucceeded(data)) {
            loggerHelper.info('{chargifyjs} 3DS payment validated', data, jwt);
            onSuccess(jwt);
        } else {
            onError(getErrorDescription(data));
        }
    });

    Cardinal.on('payments.setupComplete', (data) => {
        store.saveReference(data.sessionId);
    });

    loggerHelper.info('{chargifyjs} Cardinal Cruise configured');
    threeDSConfigured = true;
};

const initCardinal = ({ jwt }) => {
    loggerHelper.info('{chargifyjs} Cardinal Cruise init');
    Cardinal.setup('init', setupData(jwt));
};

const authorize = (formData, config, callbacks) => {
    const { onError } = callbacks;
    const data = orderData(formData, config);

    if (!threeDSConfigured) {
        onError('3D Secure not initialized', 'Please try again.');
        return;
    }

    const valid = validateForm(data, onError);
    if (!valid) return;

    store.saveFormData(formData);
    performBinDetectionThenCheckEnrollment(data, callbacks);
};

const performBinDetectionThenCheckEnrollment = (data, { onSuccess, onError }) => {
    loggerHelper.info('{chargifyjs} 3DS starting CCA');
    loggerHelper.info('{chargifyjs} 3DS data:', data);

    Cardinal.trigger('bin.process', getAccountNumber(data))
        .then(() => {
            onSuccess(store.fetchReference());
        })
        .catch((error) => {
            onError('Unexpected error occurred. Please reload the page and try again.', error)
        });
};

const startChallenge = (config) => {
    const { enrollment } = config;

    loggerHelper.info('{chargifyjs} 3DS continuing CCA');

    Cardinal.continue(
        'cca',
        continueData(enrollment),
        orderData(store.fetchFormData(), config),
    );
};

export default {
    init: init,
    authorize: authorize,
    startChallenge: startChallenge,
};
